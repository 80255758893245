import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../../components/common/Typography';
import { ROUTE_SIGNIN } from '../../../../constants';
import { Button } from '../../../../components/common/Buttons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IconReachAttempts } from '../../../../components/svg/Icons';

const Error = (props) => {
  const { classes, trans } = props;

  const history = useHistory();

  /***
   *
   */
  const goToLogin = () => {
    history.push(ROUTE_SIGNIN.path);
  };

  return (
    <div className={classes.cardContainer}>
      <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center">
        <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
          <Grid item xs={12} className={classes.signUpForm}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.iconReachedAttemptsContainer}>
                <IconReachAttempts />
              </Grid>
              <Grid item xs={12} className={classes.reachedAttemptsContainer}>
                <Typography className={classes.reachedAttempts} fontFamily="lexend" weight="500">
                  {trans('an-unexpected-error-occurred')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.solutionBox}>
                <Typography variant="body2" fontFamily="lexend" weight="600">
                  {`${trans('solution')}:`}
                </Typography>
                <Typography variant="body2" fontFamily="manrope" weight="400">
                  {trans('please-start-process-using-link-sent-email')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.backToLoginButtonContainer}>
                <Button id="back-to-login-btn" variant="outlined" size="medium" color="primary" onClick={goToLogin}>
                  {trans('back-to-login')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Error;
