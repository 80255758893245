import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const initialValues = {
  title: '',
  stages: 0,
  description: '',
  tags: [],
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    title: Yup.string().required(trans('required-field')),
    stages: Yup.number().required(trans('required-field')).positive().integer().min(0).max(50),
    description: Yup.string(),
    tags: Yup.array(
      Yup.object().shape({
        id: Yup.string(),
        value: Yup.string(),
      })
    ),
  })
);
