import React, { useEffect, useState } from 'react';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { AuthRoute } from './components/common/AuthRoute';
import Loader from './components/common/Loader/Loader';
import AppLayout from './components/layouts/AppLayout';
import {
  APP_MENU,
  ROUTES,
  ROUTE_BILLING,
  ROUTE_CHANGEPASSWORD,
  ROUTE_CHECKOUT,
  ROUTE_DASHBOARD,
  ROUTE_EXPIRATION,
  ROUTE_EZ_SEARCH,
  ROUTE_FORM_DETAIL,
  ROUTE_MIGRATION,
  ROUTE_ORG_SETTINGS,
  ROUTE_ORG_REGISTRATION_FORM,
  ROUTE_ORG_TEAMING_FORM,
  ROUTE_PASSWORD_SUCCESS,
  ROUTE_PLANS_PRIVATE,
  ROUTE_PLANS_PUBLIC,
  ROUTE_PROFILE,
  ROUTE_SETPASSWORD,
  ROUTE_SIGNIN,
  ROUTE_SIGNUP,
  ROUTE_SINGUPSUBSCRIBE,
  ROUTE_SUCCESS_PAYMENT,
  ROUTE_UNSUBSCRIBE_EMAILS,
  ROUTE_VENDORS_MANAGEMENT_LIST,
  ROUTE_VENDORS_MANAGEMENT_DETAILS,
  ROUTE_VERIFY,
  ROUTE_VIDEO_DETAIL,
  ROUTE_VIDEO_LIBRARY,
  ROUTE_FORM_DETAIL_V2,
  ROUTE_NO_ACCOUNT_FOUND,
  ROUTE_CODE_SENT,
} from './constants';
import * as APP_CONTAINERS from './containers';
import ChangePassword from './containers/Auth/ChangePassword';
import Expiration from './containers/Auth/Expiration';
import PasswordSuccess from './containers/Auth/PasswordSuccess';
import CodeSent from './containers/Auth/CodeSent';
import SetPassword from './containers/Auth/SetPassword';
import SignIn from './containers/Auth/SignIn';
import SignUp from './containers/Auth/SignUp';
import Verification from './containers/Auth/SignUp/Verification';
import Billing from './containers/Billing';
import VideoDetail from './containers/Dashboard/VideoDetail';
import VideoLibrary from './containers/Dashboard/VideoLibrary';
import EZSearch from './containers/EZSearch';
import OrgSettings from './containers/OrgSettings';
import OrganizationManualRegistrationForm from './containers/OrganizationManualRegistrationForm';
import OrganizationTeamingInformationForm from './containers/OrganizationTeamingInformationForm';
import Profile from './containers/Profile';
import Migrate from './containers/Profile/Migrate';
import VendorManagementList from './containers/Vendors/Management/List';
import VendorManagementDetails from './containers/Vendors/Management/Details';
import useLogout from './hooks/useLogout';
import { ApplicationEvents, useApplication } from './services/application';
//import { useFetchSubscriptionStatus } from './hooks/useSubscriptionStatus';
import SingupSubscribe from './components/common/Subscription/SingupSubscribe/index';
import SubscribedOnly from './components/common/Subscription/SubscribedOnly';
import UnsubscribeEmails from './containers/Auth/UnsubscribeEmails';
import Detail from './containers/Billing/InvoicesTab/components/Detail';
import Checkout from './containers/Checkout/FormCheckout';
import SuccessPayment from './containers/Checkout/SuccessPayment';
import FormDetail from './containers/Forms/FormDetail';
import OrganizationDeactivated from './containers/OrganizationDeactivated';
import { PrivatePlans, PublicPlans } from './containers/Plans';
import { AmplifyAuth } from './services/amplifyAuth';
import { getRedirectUrls } from './utils/helpers';
import FormDetailv2 from './containers/Forms/FormDetailv2';
import NoAccountFound from './containers/Auth/NoAccountFound';
import OnboardingMeeting from './containers/OnboardingMeeting';
import OnboardingMeetingCalendar from './containers/OnboardingMeeting/Calendar';

function RouterMain() {
  const app = useApplication();
  const logout = useLogout();
  const [pending, setPending] = useState(true);
  const [authorized, setAuthorized] = useState(app.authorized);
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUrl = queryParams.get('redirectUrl') ?? queryParams.get('redirectTo');
  const action = queryParams.get('action');

  const LOGIN_ACTION = 'login';
  const LEARNWORLDS_URL = 'https://usfcr-training.getlearnworlds.com/';
  const LEARNWORLDS_SECONDARY_DOMAIN = 'learn.usfcr.com';

  const redirectToLearnWorlds = async () => {
    try {
      const resp = await app.call('learnWorlds.singleSignOn', {
        redirectUrl,
      });
      const lwUrl = resp.lw?.url;
      window.location.href = lwUrl;
    } catch (err) {
      console.log('🍉 singleSignOn error ', err);
    }
  };

  useEffect(() => {
    const validateRoutes = async () => {
      let user = null;
      try {
        user = await AmplifyAuth.currentAuthenticatedUser();
      } catch (err) {
        // throw err;
      }

      if (
        user &&
        redirectUrl &&
        action &&
        action === LOGIN_ACTION &&
        (redirectUrl === LEARNWORLDS_URL || redirectUrl.includes(LEARNWORLDS_SECONDARY_DOMAIN))
      ) {
        await redirectToLearnWorlds();
      } else {
        const onAuthorize = () => setAuthorized(app.authorized);

        const onLogout = () => {
          const currentPath = history.location.pathname;
          const route = Object.values(ROUTES).find((r) => r.path === currentPath);
          if (!route || (!route.noAuth && !app.authorized)) return logout();
        };

        app
          .onReady()
          .then(() => (!app.authorized ? onLogout() : undefined))
          .finally(() => setPending(false));
        app.on(ApplicationEvents.AUTHORIZE, onAuthorize);
        app.on(ApplicationEvents.LOGOUT, onLogout);
        return () => {
          app.removeListener(ApplicationEvents.LOGOUT, onLogout);
          app.removeListener(ApplicationEvents.AUTHORIZE, onAuthorize);
        };
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps

    validateRoutes().catch((err) => {
      throw err;
    });
  }, [app, logout, action, redirectUrl]);

  const { authRedirect, notAuthRedirect } = getRedirectUrls(authorized, redirectUrl);
  const authOptions = {
    authorized,
    redirect: authRedirect,
  };

  const notAuthOptions = {
    redirect: notAuthRedirect,
    noAuth: true,
  };

  const redirectAuthUrl = authorized ? notAuthRedirect : authRedirect;
  if (redirectAuthUrl?.startsWith('http')) {
    window.location.href = redirectAuthUrl;
  }

  return pending /*|| subscriptionPending */ ? (
    <Loader open />
  ) : (
    <Switch>
      <AuthRoute path={ROUTE_SIGNIN.path} {...notAuthOptions}>
        <SignIn />
      </AuthRoute>
      <AuthRoute path={ROUTE_SIGNUP.path} {...notAuthOptions}>
        <SignUp />
      </AuthRoute>
      <AuthRoute path={ROUTE_PLANS_PUBLIC.path} {...notAuthOptions}>
        <PublicPlans />
      </AuthRoute>
      <AuthRoute path={ROUTE_PASSWORD_SUCCESS.path} {...notAuthOptions}>
        <PasswordSuccess />
      </AuthRoute>
      <AuthRoute path={ROUTE_CODE_SENT.path} {...notAuthOptions}>
        <CodeSent />
      </AuthRoute>
      <AuthRoute path={ROUTE_CHANGEPASSWORD.path} {...notAuthOptions}>
        <ChangePassword />
      </AuthRoute>
      <AuthRoute path={ROUTE_SETPASSWORD.path} {...notAuthOptions}>
        <SetPassword />
      </AuthRoute>
      <AuthRoute path={ROUTE_VERIFY.path} exact {...notAuthOptions}>
        <Verification />
      </AuthRoute>
      <AuthRoute path={ROUTE_EXPIRATION.path} {...notAuthOptions}>
        <Expiration />
      </AuthRoute>
      <AuthRoute path={ROUTE_UNSUBSCRIBE_EMAILS.path} noAuth={true}>
        <UnsubscribeEmails />
      </AuthRoute>
      <AuthRoute path={ROUTE_NO_ACCOUNT_FOUND.path} {...notAuthOptions}>
        <NoAccountFound />
      </AuthRoute>

      <AppLayout>
        <Switch>
          <AuthRoute path={ROUTE_PROFILE.path} {...authOptions}>
            <Profile />
          </AuthRoute>
          <AuthRoute path={ROUTE_MIGRATION.path} {...authOptions}>
            <Migrate />
          </AuthRoute>
          <AuthRoute path={ROUTE_ORG_SETTINGS.path} {...authOptions}>
            <SubscribedOnly handled={true}>
              <OrgSettings />
            </SubscribedOnly>
          </AuthRoute>
          <AuthRoute path={ROUTE_ORG_REGISTRATION_FORM.path} {...authOptions}>
            <OrganizationManualRegistrationForm />
          </AuthRoute>
          <AuthRoute path={ROUTE_ORG_TEAMING_FORM.path} {...authOptions}>
            <OrganizationTeamingInformationForm />
          </AuthRoute>
          <AuthRoute path={ROUTE_BILLING.path} {...authOptions}>
            <Billing />
          </AuthRoute>
          <AuthRoute path={ROUTE_PLANS_PRIVATE.path} {...authOptions}>
            <PrivatePlans />
          </AuthRoute>

          <AuthRoute path={`${ROUTE_CHECKOUT.path}/:type?/:id?`} {...authOptions}>
            <Checkout />
          </AuthRoute>
          <AuthRoute path={ROUTE_SINGUPSUBSCRIBE.path} {...authOptions}>
            <SingupSubscribe />
          </AuthRoute>
          <AuthRoute path={ROUTE_SUCCESS_PAYMENT.path} {...authOptions}>
            <SuccessPayment />
          </AuthRoute>

          <AuthRoute path={ROUTE_EZ_SEARCH.path} {...authOptions}>
            <EZSearch />
          </AuthRoute>

          <AuthRoute path={ROUTE_VIDEO_LIBRARY.path} {...authOptions}>
            <VideoLibrary />
          </AuthRoute>

          <AuthRoute path={ROUTE_VIDEO_DETAIL.path} {...authOptions}>
            <VideoDetail />
          </AuthRoute>

          <AuthRoute path={ROUTE_VENDORS_MANAGEMENT_LIST.path} {...authOptions}>
            <AppLayout>
              <VendorManagementList />
            </AppLayout>
          </AuthRoute>

          <AuthRoute path={ROUTE_VENDORS_MANAGEMENT_DETAILS.path} {...authOptions}>
            <VendorManagementDetails />
          </AuthRoute>

          <AuthRoute path={ROUTE_FORM_DETAIL.path} {...authOptions}>
            <FormDetail />
          </AuthRoute>

          <AuthRoute path={ROUTE_FORM_DETAIL_V2.path} {...authOptions}>
            <FormDetailv2 />
          </AuthRoute>

          <AuthRoute path={'/organization-deactivated'} {...authOptions}>
            <OrganizationDeactivated />
          </AuthRoute>

          <AuthRoute path={'/invoice/:id'} {...authOptions}>
            <Detail />
          </AuthRoute>

          {/*<AuthRoute path={ROUTE_VENDORS_MANAGEMENT_LIST.path} {...authOptions}>
            <AppLayout>
              <VendorManagementList />
            </AppLayout>
          </AuthRoute>*/}

          {/* Show contain of each menu item */}
          {APP_MENU.map((group, sectionIndex) =>
            group.items.map((route, index) => {
              const Component = APP_CONTAINERS[route.component] || 'div';
              return (
                <AuthRoute key={`${sectionIndex}-${index}`} path={route.path} {...authOptions}>
                  <SubscribedOnly handled={!route.subscribedOnly}>
                    <Component />
                  </SubscribedOnly>
                </AuthRoute>
              );
            })
          )}
          {redirectAuthUrl && <Redirect exact path="/*" to={redirectAuthUrl} />}
        </Switch>
      </AppLayout>
    </Switch>
  );
}

export default RouterMain;
