import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonResend: {
    margin: '32px 0px 32px 0px',
    weight: 108,
  },
  formBack: {
    padding: '3rem',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      padding: '1.875rem',
      boxShadow: 'none',
      height: 'auto',
    },
  },
  topHeader: {
    color: theme.palette.gray.main,
    width: '100%',
    marginBottom: 16,
  },
  signHeader: {
    color: theme.palette.gray.light,
    width: '100%',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  signHeaderElementContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  signForm: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  flexHelper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    height: '3rem',
  },
  centeredBlock: {
    maxWidth: 420,
  },
  centeredBlockInfoState: {
    maxWidth: 440,
  },
  verificationTitle: {
    marginTop: 24,
    lineHeight: 1.3,
    marginBottom: 12,
  },
  alreadyVerifiedTitle: {
    marginTop: 30,
    lineHeight: 1.3,
    marginBottom: '2.5rem',
    fontSize: '2rem',
    textAlign: 'center',
  },
  alreadyVerifiedSubTitle: {
    marginBottom: '2.5rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0.8rem',
    },
  },
  email: {
    marginBottom: 38,
  },
  link: {
    marginLeft: 10,
  },
  codeRow: {
    marginBottom: 30,
  },
  helpRow: {
    color: theme.palette.gray.light,
    display: 'flex',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      // justifyContent: 'flex-start',
      marginTop: '.5rem',
    },
  },
  codeHelperText: {
    marginTop: 10,
    marginBottom: 30,
  },
  hintIcon: {
    marginRight: 10,
    marginLeft: 10,
  },
  authStateImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '4rem',
  },
  authStateImage: {
    width: 140,
    height: 140,
    marginBottom: -24,
    marginLeft: -12,
  },
  authStateMessage: {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      gap: '0.5rem',
      '& span': {
        textAlign: 'end',
      },
      '& a': {
        whiteSpace: 'nowrap',
      },
    },
  },
  modalText: {
    marginBottom: 15,
  },
  loginButton: {
    height: 64,
    borderRadius: 8,
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      height: 48,
    },
  },
  mainActions: {
    margin: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0',
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  signUpForm: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  cardContainer: {
    padding: '0rem 2.3rem',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: '0rem 1.5rem',
      boxShadow: 'none',
      height: 'auto',
    },
  },
  setNewPasswordTitle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5rem',
    marginBottom: '-1rem',
  },
  setNewPasswordDesc: {
    display: 'flex',
    textAlign: 'center',
    marginTop: '0rem',
    marginBottom: '1rem',
  },
  setPasswordButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  rememberPassContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5rem',
  },
  validationCodeError: {
    marginTop: '0.4rem',
  },
  reachedAttempts: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
  iconReachedAttemptsContainer: {
    textAlign: 'center',
    marginTop: '4rem',
  },
  reachedAttemptsContainer: {
    textAlign: 'center',
    marginTop: '-1.2rem',
    marginBottom: '0.5rem',
  },
  solutionBox: {
    borderRadius: '1rem',
    backgroundColor: '#526FFF14',
    padding: '1rem !important',
    marginTop: '1rem',
    marginBottom: '1.6rem',
  },
  backToLoginButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5rem',
  },
  verificationCodeExpired: {
    textAlign: 'center',
  },
  verificationCodeExpiredText: {
    color: '#F32445',
  },
  needNewCodeContainer: {
    fontFamily: 'manrope',
    fontWeight: '500',
    fontSize: '1rem',
    color: '#888FA9',
  },
  needNewResendContainer: {
    fontFamily: 'manrope',
    fontWeight: '500',
    fontSize: '1rem',
    color: '#2445A7',
    cursor: 'pointer',
  },
  iconNewCodeSentContainer: {
    textAlign: 'center',
    marginTop: '6rem',
  },
  newCodeSentContainer: {
    textAlign: 'center',
    marginTop: '-1.2rem',
  },
  newCodeSent: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
  newCodeSentSubTitle: {
    fontSize: '1rem',
    lineHeight: '1.366rem',
  },
  newCodeSentEmail: {
    fontSize: '1rem',
    lineHeight: '1.366rem',
    color: '#F32445',
  },
  newCodeSentSubTitleContainer: {
    textAlign: 'center',
    marginTop: '-1rem',
  },
  newCodeSentEmailContainer: {
    textAlign: 'center',
    marginTop: '-1rem',
  },
  plr1rem: {
    padding: '0.3rem 0.5rem',
  },
  notReceiveLetterContainer: {
    textAlign: 'center',
    marginBottom: '4rem',
  },
  notReceiveLetter: {
    fontFamily: 'manrope',
    fontWeight: '400',
    fontSize: '0.875rem',
    color: '#888FA9',
  },
  notReceiveLetterResend: {
    fontFamily: 'manrope',
    fontWeight: '400',
    fontSize: '0.875rem',
    color: '#2445A7',
    cursor: 'pointer',
  },
  weJustSend: {
    fontFamily: 'manrope',
    fontWeight: '500',
    fontSize: '0.875rem',
    color: '#17B471',
  },
  ifYouCantFind: {
    fontFamily: 'manrope',
    fontWeight: '400',
    fontSize: '0.875rem',
    color: '#4E5978',
  },
  iconPasswordChangedContainer: {
    textAlign: 'center',
    marginTop: '10rem',
  },
  passwordChangedText: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
  youCanNowLoginText: {
    fontSize: '1rem',
  },
  passwordChangedContainer: {
    textAlign: 'center',
    marginTop: '-1.2rem',
    marginBottom: '0.5rem',
  },
  youCanNowLoginContainer: {
    textAlign: 'center',
    marginTop: '-1.4rem',
    marginBottom: '0.8rem',
  },
  mb5rem: {
    marginBottom: '10rem',
  },
}));
