import React, { useState } from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../components/common/Typography';
import { TextField } from '../../../components/common/Inputs';
import { Button } from '../../../components/common/Buttons';
import useStyles from '../styles';
import { useForm } from '../../../hooks/useForm';
import { schemaToInitialState } from '../../../utils/validationUtils';
import makeValidationSchema from '../../../hooks/makeValidationSchema';
import { useTrans } from '../../../services/i18n';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CodeInput from '../../../components/common/Inputs/CodeInput';
import { useLocation } from 'react-router-dom';
import { application } from '../../../services/application';

function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    verificationCode: Yup.string()
      .length(6, trans('verification-code-incomplete'))
      .required(trans('empty-not-allowed', { field: trans('verification-code') })),
    password: Yup.string()
      .password(trans('incorrect-password'))
      .required(trans('empty-not-allowed', { field: trans('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], trans('passwords-match'))
      .required(trans('empty-not-allowed', { field: trans('confirm-new-password') })),
  })
);

function SetPasswordForm({ onSubmit, loading, error }) {
  const { trans } = useTrans();
  const classes = useStyles();
  const schema = useScheme();
  const [inputTypePassword, setInputTypePassword] = React.useState('password');
  const [inputTypeConfirmPassword, setInputTypeConfirmPassword] = React.useState('password');
  const [pending, setPending] = useState(false);
  const queryParams = useQueryParams();
  const email = queryParams.get('email');

  const handleVerificationCodeChange = (newCode) => {
    formik.setFieldValue('verificationCode', newCode);
  };

  const formik = useForm(schema, schemaToInitialState(schema), onSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleVisibilityPassword = () => {
    setInputTypePassword(inputTypePassword === 'password' ? 'text' : 'password');
  };

  const handleVisibilityConfirmPassword = () => {
    setInputTypeConfirmPassword(inputTypeConfirmPassword === 'password' ? 'text' : 'password');
  };

  const handleResendCode = async () => {
    if (!email) {
      console.error('No email provided in the URL');
      return;
    }

    setPending(true);
    try {
      const { success, reason } = await application.call('user.forgotPassword', { email });

      if (!success) {
        console.error('Resend failed:', reason);
        return;
      }

      console.log('Code resent successfully.');
      window.location.href = `/code-sent?email=${email}`;
    } catch (error) {
      console.error('Error resending verification code:', error);
    } finally {
      setPending(false);
    }
  };

  return (
    <div className={classes.formBack}>
      <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
        <Grid item xs={12} className={classes.signForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h1Medium" fontFamily="lexend" weight="500">
                {trans('set-password')}
              </Typography>
              <Typography
                variant="body1"
                fontFamily="lexend"
                weight="400"
                style={{ marginTop: '8px', textAlign: 'center' }}
              >
                {trans('please-enter-verification-code')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                fontFamily="lexend"
                style={{ fontSize: '14px', marginBottom: '10px', fontWeight: '500', color: '#657085' }}
              >
                {trans('verification-code')}
              </Typography>
              <Grid item xs={12} className={classes.codeRow}>
                <CodeInput
                  type="number"
                  onChange={handleVerificationCodeChange}
                  value={formik.values.verificationCode || ''}
                />
              </Grid>
              {formik.errors.verificationCode && formik.touched.verificationCode && (
                <Typography
                  variant="body2"
                  style={{ color: 'red', marginTop: '8px', textAlign: 'center', fontWeight: '500' }}
                >
                  {formik.errors.verificationCode}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label={trans('password')}
                type={inputTypePassword}
                placeholder={trans('password')}
                {...formik.getFieldProps('password')}
                required
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={handleVisibilityPassword} position="end" className={classes.cursorPointer}>
                      {inputTypePassword === 'password' ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                type={inputTypeConfirmPassword}
                label={trans('confirm-new-password')}
                placeholder={trans('confirm-new-password')}
                {...formik.getFieldProps('confirmPassword')}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={handleVisibilityConfirmPassword}
                      position="end"
                      className={classes.cursorPointer}
                    >
                      {inputTypeConfirmPassword === 'password' ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                fontFamily="lexend"
                style={{ fontSize: '14px', fontWeight: '600', color: '#657085' }}
              >
                {trans('password-requirements-label')}
              </Typography>
              <Typography
                variant="body2"
                fontFamily="lexend"
                style={{ marginTop: '5px', fontWeight: '500', textAlign: 'justify', color: '#657085' }}
              >
                {trans('password-requirements')}
              </Typography>
            </Grid>
            {error && (
              <>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography
                    variant="body1"
                    fontFamily="lexend"
                    style={{ fontSize: '14px', fontWeight: '600', color: 'red' }}
                  >
                    {trans('verification-code-invalid-or-expired')}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant="text" color="primary">
                    {trans('need-new-code')}
                    <span
                      onClick={handleResendCode}
                      style={{ color: '#2445a7', fontWeight: 'bold', cursor: 'pointer' }}
                    >
                      {trans('resend')}
                    </span>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                id="change-password-btn"
                variant="contained"
                size="large"
                pending={loading}
                color="primary"
                onClick={formik.handleSubmit}
              >
                {trans('set-password')}
              </Button>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant="text" color="primary" onClick={() => (window.location.href = '/login')}>
                {trans('back-to-login')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default SetPasswordForm;
