import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../../components/common/Typography';
import { ROUTE_SIGNIN } from '../../../../constants';
import { Button } from '../../../../components/common/Buttons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IconPasswordChanged } from '../../../../components/svg/Icons';

const Verified = (props) => {
  const { classes, trans } = props;

  const history = useHistory();

  /***
   *
   */
  const goToLogin = () => {
    history.push(ROUTE_SIGNIN.path);
  };

  return (
    <div className={classes.cardContainer}>
      <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center">
        <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
          <Grid item xs={12} className={classes.signUpForm}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.iconPasswordChangedContainer}>
                <IconPasswordChanged />
              </Grid>
              <Grid item xs={12} className={classes.passwordChangedContainer}>
                <Typography className={classes.passwordChangedText} fontFamily="lexend" weight="500">
                  {trans('password-changed2')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.youCanNowLoginContainer}>
                <Typography className={classes.youCanNowLoginText} fontFamily="manrope" weight="400">
                  {trans('you-can-now-log-in-with-your-new password')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={`${classes.backToLoginButtonContainer} ${classes.mb5rem}`}>
                <Button id="back-to-login-btn" variant="outlined" size="medium" color="primary" onClick={goToLogin}>
                  {trans('back-to-login')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Verified;
