import React from 'react';
import Loader from '../../../components/common/Loader';
import AuthLayout from '../../../components/layouts/AuthLayout';
import ChangePasswordForm from './ChangePasswordForm';
import useChangePassword from './useChangePassword';

const ChangePassword = () => {
  const { handleSubmit, pending, error } = useChangePassword();
  return (
    <>
      <Loader open={pending} />
      <AuthLayout>{!pending && <ChangePasswordForm onSubmit={handleSubmit} error={error} />}</AuthLayout>
    </>
  );
};

export default ChangePassword;
