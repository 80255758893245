import React from 'react';
import { Grid, InputAdornment, Link } from '@material-ui/core';
import Typography from '../../../../components/common/Typography';
import { ROUTE_SIGNIN } from '../../../../constants';
import { useForm } from '../../../../hooks/useForm';
import { schemaToInitialState } from '../../../../utils/validationUtils';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import * as Yup from 'yup';
import { CodeInput, TextField } from '../../../../components/common/Inputs';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Button } from '../../../../components/common/Buttons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CODE_MISMATCH_EXCEPTION } from '../../../../utils/exceptions';

const useStyles = makeStyles((theme) => ({
  codeInput: {
    '& > input': {
      marginRight: 7,
      width: 63,
      fontSize: '0.875rem',
      height: 54,
      [theme.breakpoints.down('xs')]: {
        width: 50,
      },
      [theme.breakpoints.down('sm')]: {
        width: 47,
      },
      outline: 'none',
    },
  },
  codeInputError: {
    '& > input': {
      marginRight: 7,
      width: 63,
      fontSize: '0.875rem',
      color: '#F32445',
      backgroundColor: '#F3244514',
      height: 54,
      [theme.breakpoints.down('xs')]: {
        width: 50,
      },
      [theme.breakpoints.down('sm')]: {
        width: 47,
      },
      outline: 'none',
    },
  },
}));

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    password: Yup.string()
      .password(trans('incorrect-password'))
      .required(trans('empty-not-allowed', { field: trans('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], trans('passwords-match'))
      .required(trans('empty-not-allowed', { field: trans('confirm-new-password') })),
  })
);

const VerifyEmail = (props) => {
  const {
    classes,
    query,
    trans,
    state,
    pending,
    verifyAccount,
    validateCode,
    setCode,
    codeError,
    verificationResult,
    resendVerification,
  } = props;

  const schema = useScheme();
  const customClasses = useStyles();
  const history = useHistory();
  const [inputTypePassword, setInputTypePassword] = React.useState('password');
  const [inputTypeConfirmPassword, setInputTypeConfirmPassword] = React.useState('password');

  const inputRef = React.useRef(null);

  // Custom handler for button click
  const handleSetPasswordClick = () => {
    validateCode(); // Call the additional function
    formik.handleSubmit(); // Call the form submission handler
  };

  // Custom handler for button click
  const handleResendCodeClick = async () => {
    await resendVerification();
  };

  const formik = useForm(schema, schemaToInitialState(schema), verifyAccount, {
    validateOnChange: false,
    validateOnBlur: false,
  });

  /***
   *
   */
  const goToLogin = () => {
    history.push(ROUTE_SIGNIN.path);
  };

  /***
   *
   */
  const handleVisibilityPassword = () => {
    setInputTypePassword(inputTypePassword === 'password' ? 'text' : 'password');
  };

  /***
   *
   */
  const handleVisibilityConfirmPassword = () => {
    setInputTypeConfirmPassword(inputTypeConfirmPassword === 'password' ? 'text' : 'password');
  };

  return (
    <div className={classes.cardContainer}>
      <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center">
        <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
          <Grid item xs={12} className={classes.signUpForm}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.setNewPasswordTitle}>
                <Typography variant="h1" fontFamily="lexend" weight="500">
                  {trans('set-new-password2')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.setNewPasswordDesc}>
                <Typography variant="body1" fontFamily="manrope" weight="400">
                  {trans('set-new-password-desc')}
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Typography gutter="label" fontFamily="lexend" variant="body2" component="p" weight="500" color="label">
                  {trans('verification-code')}
                </Typography>
                <CodeInput
                  name="code"
                  ref={inputRef}
                  fields={6}
                  type="number"
                  className={
                    verificationResult === CODE_MISMATCH_EXCEPTION
                      ? customClasses.codeInputError
                      : customClasses.codeInput
                  }
                  onChange={(e) => setCode(e)}
                />
                <Typography
                  fontFamily="manrope"
                  variant="caption"
                  component="p"
                  weight="400"
                  color="error"
                  className={classes.validationCodeError}
                >
                  {codeError}
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label={trans('new-password')}
                  type={inputTypePassword}
                  placeholder={trans('required')}
                  {...formik.getFieldProps('password')}
                  required
                  name="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={handleVisibilityPassword}
                        position="end"
                        className={classes.cursorPointer}
                      >
                        {inputTypePassword === 'password' ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  type={inputTypeConfirmPassword}
                  label={trans('confirm-new-password')}
                  placeholder={trans('required')}
                  {...formik.getFieldProps('confirmPassword')}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={handleVisibilityConfirmPassword}
                        position="end"
                        className={classes.cursorPointer}
                      >
                        {inputTypeConfirmPassword === 'password' ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {verificationResult === CODE_MISMATCH_EXCEPTION && (
                <Grid item xs={12} className={classes.verificationCodeExpired}>
                  <Typography
                    fontFamily="manrope"
                    variant="body1"
                    component="p"
                    weight="600"
                    className={classes.verificationCodeExpiredText}
                  >
                    {`${trans('verification-code-invalid-or-expired')}`}
                  </Typography>
                  <>
                    <span className={classes.needNewCodeContainer}>{`${trans('need-new-code')}? `}</span>
                    <span
                      className={classes.needNewResendContainer}
                      onClick={pending ? null : handleResendCodeClick}
                    >{`${trans('resend')}`}</span>
                  </>
                </Grid>
              )}
              <Grid item xs={12} className={classes.setPasswordButtonContainer}>
                <Button
                  id="change-password-btn"
                  variant="contained"
                  size="large"
                  pending={pending}
                  color="primary"
                  onClick={handleSetPasswordClick}
                >
                  {trans('set-password')}
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.rememberPassContainer}>
                <Link onClick={pending ? null : goToLogin}>
                  <Typography variant="body2" weight="500">
                    {`${trans('back-to-login')}`}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default VerifyEmail;
