import React from 'react';
import { ROUTE_SIGNIN } from '../../../../constants';
import InfoState from '../../../../components/organims/InfoState';

const AlreadyVerified = (props) => {
  const { classes, trans } = props;

  return (
    <div className={classes.centeredBlockInfoState}>
      <InfoState
        title={trans('your-account-successfully-created-please-log-in')}
        subTitle={`No further action is required. Please continue using the password you have already set.`}
        buttonText={trans('log-in')}
        to={ROUTE_SIGNIN.path}
      />
    </div>
  );
};

export default AlreadyVerified;
