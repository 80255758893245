import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconPasswordChanged = (props) => {
  const { width, height } = props;

  return (
    <svg
      width={width || 140}
      height={height || 140}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_21402_5027)">
        <circle cx="70" cy="60" r="50" fill="white" />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70 32L69.6759 32.0033C60.9703 32.1819 54 39.2881 54 48V52H47C44.7909 52 43 53.7909 43 56V84C43 86.2091 44.7909 88 47 88H93C95.2091 88 97 86.2091 97 84V56C97 53.7909 95.2091 52 93 52H86V48C86 39.1634 78.8366 32 70 32ZM84 52V48C84 40.3725 77.9002 34.1697 70.3126 34.0034L70.0103 33.9999L69.7067 34.003C62.0991 34.1591 56 40.3772 56 48V52H84ZM72 72.6586C74.3304 71.8349 76 69.6124 76 67C76 63.6863 73.3137 61 70 61C66.6863 61 64 63.6863 64 67C64 69.6124 65.6696 71.8349 68 72.6586V77C68 78.1046 68.8954 79 70 79C71.1046 79 72 78.1046 72 77V72.6586Z"
        fill="#2445A7"
      />
      <circle cx="104" cy="26" r="16" fill="#17B471" />
      <path
        d="M108.287 22.2993C108.674 21.9053 109.307 21.8996 109.701 22.2866C110.064 22.6438 110.097 23.2107 109.796 23.6058L109.713 23.7007L102.838 30.7007C102.477 31.0691 101.901 31.0974 101.506 30.7857L101.412 30.7007L98.2866 27.5189C97.8996 27.1249 97.9053 26.4917 98.2993 26.1047C98.663 25.7475 99.2305 25.7249 99.62 26.0334L99.7134 26.1175L102.125 28.573L108.287 22.2993Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_21402_5027"
          x="0"
          y="0"
          width="140"
          height="140"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.305882 0 0 0 0 0.34902 0 0 0 0 0.470588 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21402_5027" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21402_5027" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

IconPasswordChanged.propTypes = simpleIconPropsTypes;
