import { useCallback, useEffect, useState } from 'react';
import { ROUTE_EXPIRATION } from '../../../constants';
import { useAlertContext } from '../../../contexts/AlertContext';
import useExceptionsAction from '../../../hooks/dispatchers/useExceptionsAction';
import useGlobalSettings from '../../../hooks/dispatchers/useGlobalSettings';
import useQueryHistory from '../../../hooks/query/useQueryHistory';

import { useApplication } from '../../../services/application';
import { useTrans } from '../../../services/i18n';
import {
  CODE_MISMATCH_EXCEPTION,
  ERROR,
  EXPIRED_CODE_EXCEPTION,
  EXPIRED_TOKEN_EXCEPTION_CODE,
  INIT,
  LIMITED_EXCEEDED_EXCEPTION_CODE,
  TOO_MANY_FAILED_ATTEMPTS_EXCEPTION_CODE,
  USER_ALREADY_CONFIRMED_CODE,
  USER_CONFIRMED_CODE,
  VERIFICATION_CODE_RESENT_CODE,
} from '../../../utils/exceptions';
import useStyles from '../styles';

/***
 *
 */
export function useResendCode() {
  const [pending, setPending] = useState();
  const { successAlert, errorAlert } = useAlertContext();
  const application = useApplication();

  const resendCode = useCallback(
    async (email) => {
      setPending(true);

      try {
        await application.call('user.resendConfirmationCode', { email });
        successAlert('Confirmation link was re-sent successfully', 'Please check your email');
        setTimeout(() => setPending(false), 10000);
      } catch {
        errorAlert('Could not connect to the server, try again in a few minutes.');
        setPending(false);
      }
    },
    [application, successAlert, errorAlert]
  );

  return { pending, resendCode };
}

export default function useVerification() {
  const { setEmailExpiredD } = useGlobalSettings();
  const { setExceptionsD } = useExceptionsAction();
  const application = useApplication();
  const classes = useStyles();
  const { trans } = useTrans();
  const { history, query } = useQueryHistory();
  const { state = {} } = history.location;
  const [pending, setPending] = useState();
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [attemptsCount, setAttemptsCount] = useState(0);
  const [verificationResult, setVerificationResult] = useState(INIT);

  /**
   * Validates the verification code.
   * @returns {boolean} True if the code is valid, otherwise false.
   */
  const validateCode = useCallback(() => {
    // Check if the code is empty
    if (!code) {
      setCodeError(trans('verification-code-required'));
      return false;
    }

    // Check if the code is exactly 6 digits
    if (code.length !== 6) {
      setCodeError(trans('enter-valid-6-digit-code'));
      return false;
    }

    // Clear any existing error
    setCodeError('');
    return true;
  }, [code]);

  /***
   *
   */
  const verifyAccount = useCallback(
    (values) => {
      // Validates that the code is correct
      if (!validateCode(code)) return;
      setPending(true);
      return application
        .call('user.confirmEmail', {
          email: query.email,
          code: code,
          password: values.password,
        })
        .then((res) => {
          const codeMapping = {
            [EXPIRED_TOKEN_EXCEPTION_CODE]: ERROR,
            [USER_ALREADY_CONFIRMED_CODE]: USER_ALREADY_CONFIRMED_CODE,
            [USER_CONFIRMED_CODE]: USER_CONFIRMED_CODE,
            [CODE_MISMATCH_EXCEPTION]: CODE_MISMATCH_EXCEPTION,
            [EXPIRED_CODE_EXCEPTION]: CODE_MISMATCH_EXCEPTION,
            [TOO_MANY_FAILED_ATTEMPTS_EXCEPTION_CODE]: LIMITED_EXCEEDED_EXCEPTION_CODE,
            [LIMITED_EXCEEDED_EXCEPTION_CODE]: LIMITED_EXCEEDED_EXCEPTION_CODE,
          };

          const result = codeMapping[res.code] || ERROR;
          setVerificationResult(result);
        })
        .catch((e) => {
          console.log('Error: ', e);
          setVerificationResult(ERROR);
        })
        .finally(() => setPending(false));
    },
    [application, history, query.code, query.email, setExceptionsD, setEmailExpiredD, code]
  );

  /***
   *
   */
  const resendVerification = useCallback(async () => {
    setPending(true);
    return await application
      .call('user.resendConfirmationCode', { email: query.email })
      .then((res) => {
        const codeMapping = {
          [EXPIRED_TOKEN_EXCEPTION_CODE]: ERROR,
          [USER_ALREADY_CONFIRMED_CODE]: USER_ALREADY_CONFIRMED_CODE,
          [VERIFICATION_CODE_RESENT_CODE]: VERIFICATION_CODE_RESENT_CODE,
          [CODE_MISMATCH_EXCEPTION]: CODE_MISMATCH_EXCEPTION,
          [EXPIRED_CODE_EXCEPTION]: CODE_MISMATCH_EXCEPTION,
          [TOO_MANY_FAILED_ATTEMPTS_EXCEPTION_CODE]: LIMITED_EXCEEDED_EXCEPTION_CODE,
          [LIMITED_EXCEEDED_EXCEPTION_CODE]: LIMITED_EXCEEDED_EXCEPTION_CODE,
        };

        const result = codeMapping[res.code] || ERROR;
        setVerificationResult(result);
        setAttemptsCount((prevCount) => prevCount + 1);
      })
      .catch((e) => {
        console.log('Error: ', e);
        setVerificationResult(ERROR);
      })
      .finally(() => setPending(false));
  }, [application, query.email, state.email]);

  return {
    classes,
    query,
    trans,
    state,
    pending,
    verifyAccount,
    resendVerification,
    codeError,
    setCode,
    validateCode,
    verificationResult,
    email: query.email,
    attemptsCount,
  };
}
