import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconReachAttempts = (props) => {
  const { width, height } = props;

  return (
    <svg
      width={width || 140}
      height={height || 140}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_21402_5904)">
        <circle cx="70" cy="60" r="50" fill="white" />
      </g>
      <path
        d="M81.7132 36.7203C80.5932 35.6003 78.3532 34.667 76.7665 34.667H63.2332C61.6465 34.667 59.4065 35.6003 58.2865 36.7203L48.7198 46.287C47.5998 47.407 46.6665 49.647 46.6665 51.2337V64.767C46.6665 66.3537 47.5998 68.5937 48.7198 69.7137L52.2898 73.2837L85.2832 40.2903L81.7132 36.7203Z"
        fill="#2445A7"
      />
      <path
        d="M91.2799 46.287L87.7565 42.7637L54.7632 75.757L58.2865 79.2803C59.4065 80.4003 61.6465 81.3337 63.2332 81.3337H76.7665C78.3532 81.3337 80.5932 80.4003 81.7132 79.2803L91.2799 69.7137C92.3999 68.5937 93.3332 66.3537 93.3332 64.767V51.2337C93.3332 49.647 92.3999 47.407 91.2799 46.287Z"
        fill="#2445A7"
      />
      <path
        d="M47.8097 77.7632C47.1331 78.4399 47.1331 79.5599 47.8097 80.2365C48.1597 80.5865 48.6031 80.7499 49.0464 80.7499C49.4897 80.7499 49.9331 80.5865 50.2831 80.2365L54.7631 75.7565L52.2897 73.2832L47.8097 77.7632Z"
        fill="#2445A7"
      />
      <path
        d="M92.2365 38.2836C92.9132 37.6069 92.9132 36.4869 92.2365 35.8102C91.5599 35.1336 90.4399 35.1336 89.7632 35.8102L85.2832 40.2902L87.7565 42.7636L92.2365 38.2836Z"
        fill="#2445A7"
      />
      <circle cx="104" cy="26" r="16" fill="#F32445" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M105 18H103V28H105V18ZM105 30H103V32H105V30Z" fill="white" />
      <defs>
        <filter
          id="filter0_d_21402_5904"
          x="0"
          y="0"
          width="140"
          height="140"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.305882 0 0 0 0 0.34902 0 0 0 0 0.470588 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21402_5904" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21402_5904" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

IconReachAttempts.propTypes = simpleIconPropsTypes;
