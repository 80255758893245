import React from 'react';
import Typography from '../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from '../styles';
import { useTrans } from '../../../services/i18n';
import AuthLayout from '../../../components/layouts/AuthLayout';
import passwordChanged from '../../../components/assests/ico-password-changed.png';
import { Button } from '../../../components/common/Buttons';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE_SIGNIN } from '../../../constants';

const PasswordSuccess = () => {
  const classes = useStyles();
  const { trans } = useTrans();

  return (
    <AuthLayout>
      <div className={classes.formBack} style={{ padding: '130px 20px' }}>
        <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
          <Grid item xs={12} className={classes.signForm}>
            <Grid item xs={12} style={{ textAlignLast: 'center' }}>
              <img src={passwordChanged} alt="expiration" className={classes.authStateImage} />
            </Grid>
            <Typography
              variant="h1Medium"
              fontFamily="lexend"
              weight="500"
              className={classes.verificationTitle}
              style={{ textAlign: 'center' }}
            >
              {trans('password-changed')}
            </Typography>
            <Typography
              component="p"
              weight="500"
              variant="body1"
              className={classes.email}
              style={{ textAlign: 'center' }}
            >
              {trans('you-can-now-log-in')}
            </Typography>
            <Grid item xs={6} style={{ margin: '0 auto' }}>
              <Button
                id="sign-in-btn"
                color="primary"
                variant="outlined"
                size="large"
                fullWidth
                component={RouterLink}
                to={ROUTE_SIGNIN.path}
              >
                {trans('back-to-login')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
};

export default PasswordSuccess;
