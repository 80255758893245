import React, { useState } from 'react';
import Typography from '../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from '../styles';
import { useTrans } from '../../../services/i18n';
import AuthLayout from '../../../components/layouts/AuthLayout';
import mailSent from '../../../components/assests/ico-mail-checked.png';
import { useLocation } from 'react-router-dom';
import { application } from '../../../services/application';

function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CodeSent = () => {
  const classes = useStyles();
  const { trans } = useTrans();
  const [pending, setPending] = useState(false);
  const [mustConfirm, setMustConfirm] = React.useState(false);
  const [userNotExists, setUserNotExists] = React.useState(false);
  const [showResendConfirmation, setShowResendConfirmation] = useState(false);
  const queryParams = useQueryParams();
  const email = queryParams.get('email');

  const handleResendCode = async () => {
    if (!email) {
      console.error('No email provided in the URL');
      return;
    }

    setPending(true);
    try {
      const { success, reason } = await application.call('user.forgotPassword', { email });

      if (!success) {
        if (reason === 'not-confirmed') {
          setMustConfirm(true);
          setUserNotExists(false);
        } else if (reason === 'user-does-not-exist') {
          setMustConfirm(false);
          setUserNotExists(true);
        }
        return;
      }
      setMustConfirm(false);
      setUserNotExists(false);
      setShowResendConfirmation(true);
      console.log('Code resent successfully.');
    } catch (error) {
      console.error('Error resending verification code:', error);
    } finally {
      setPending(false);
    }
  };

  return (
    <AuthLayout>
      <div className={classes.formBack} style={{ padding: '80px 30px' }}>
        <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
          <Grid item xs={12} className={classes.signForm}>
            <Grid item xs={12} style={{ textAlignLast: 'center' }}>
              <img src={mailSent} alt="expiration" className={classes.authStateImage} />
            </Grid>
            <Typography
              variant="h1Medium"
              fontFamily="lexend"
              weight="500"
              className={classes.verificationTitle}
              style={{ textAlign: 'center', fontSize: 'xx-large' }}
            >
              {trans('new-code-has-been-sent')}
            </Typography>
            <Typography component="p" weight="500" variant="body1" style={{ textAlign: 'center' }}>
              {trans('for-further-instructions-check-your-email')}
            </Typography>
            <Typography
              component="p"
              weight="500"
              variant="body1"
              style={{ textAlign: 'center', color: 'red', marginBottom: '20px' }}
            >
              {email}
            </Typography>
            <div style={{ backgroundColor: '#f8f9fd', padding: '25px', borderRadius: '12px', marginBottom: '20px' }}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="body2" style={{ fontWeight: '700', marginBottom: '5px' }}>
                    {trans('cant-find-it2')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className={classes.listItem}>
                    1. {trans('check-your-spam-folder')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className={classes.listItem}>
                    2. {trans('make-sure-email-sent-the-correct-address')}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {!showResendConfirmation ? (
              <Grid item xs={12} style={{ textAlign: 'center', color: '#657085' }}>
                <Typography variant="text" color="primary">
                  {trans('did-not-receive-letter2')}
                  <span onClick={handleResendCode} style={{ color: '#2445a7', fontWeight: 'bold', cursor: 'pointer' }}>
                    {trans('resend')}
                  </span>
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  fontFamily="lexend"
                  style={{ fontWeight: '500', color: '#17b471', textAlign: 'center' }}
                >
                  {trans('we-have-just-sent-you-email-once-more')}
                </Typography>
                <Typography
                  variant="body2"
                  fontFamily="lexend"
                  style={{ fontWeight: '300', textAlign: 'center', color: '#657085' }}
                >
                  {trans('if-you-cant-find-email-please-check-possible-solutions-above')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
};

export default CodeSent;
