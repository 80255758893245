import React from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import useStyles from '../styles';
import { useForm } from '../../../hooks/useForm';
import { schemaToInitialState } from '../../../utils/validationUtils';
import makeValidationSchema from '../../../hooks/makeValidationSchema';
import PasswordFields from './components/PasswordFields';

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    verificationCode: Yup.string()
      .length(6, trans('verification-code-incomplete'))
      .required(trans('empty-not-allowed', { field: trans('verification-code') })),
    password: Yup.string()
      .password(trans('incorrect-password'))
      .required(trans('empty-not-allowed', { field: trans('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], trans('passwords-match'))
      .required(trans('empty-not-allowed', { field: trans('confirm-new-password') })),
  })
);

function ChangePasswordForm({ onSubmit, error }) {
  const classes = useStyles();
  const schema = useScheme();
  const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState(false);

  const formik = useForm(
    schema,
    {
      verificationCode: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit,
    {
      validateOnChange: hasBeenSubmitted,
      validateOnBlur: hasBeenSubmitted,
    }
  );

  const handleVerificationCodeChange = (newCode) => {
    formik.setFieldValue('verificationCode', newCode);
  };

  const handleSubmit = () => {
    setHasBeenSubmitted(true);
    formik.handleSubmit();
  };

  return (
    <div className={classes.formBack}>
      <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
        <Grid item xs={12} className={classes.signForm}>
          <Grid container spacing={3}>
            <PasswordFields
              formikProps={formik}
              classes={classes}
              onVerificationCodeChange={handleVerificationCodeChange}
              verificationCode={formik.values.verificationCode}
              onSubmit={handleSubmit}
              error={error}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ChangePasswordForm;
