import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconNewCodeSent = (props) => {
  const { width, height } = props;

  return (
    <svg
      width={width || 140}
      height={height || 140}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_21402_5842)">
        <circle cx="70" cy="60" r="50" fill="white" />
      </g>
      <path
        d="M81.6665 40.167H58.3332C51.3332 40.167 46.6665 43.667 46.6665 51.8337V68.167C46.6665 76.3337 51.3332 79.8337 58.3332 79.8337H81.6665C88.6665 79.8337 93.3332 76.3337 93.3332 68.167V51.8337C93.3332 43.667 88.6665 40.167 81.6665 40.167ZM82.7632 54.377L75.4598 60.2103C73.9198 61.447 71.9598 62.0537 69.9998 62.0537C68.0398 62.0537 66.0565 61.447 64.5398 60.2103L57.2365 54.377C56.4898 53.7703 56.3732 52.6503 56.9565 51.9037C57.5632 51.157 58.6598 51.017 59.4065 51.6237L66.7098 57.457C68.4832 58.8803 71.4932 58.8803 73.2665 57.457L80.5698 51.6237C81.3165 51.017 82.4365 51.1337 83.0198 51.9037C83.6265 52.6503 83.5098 53.7703 82.7632 54.377Z"
        fill="#2445A7"
      />
      <circle cx="104" cy="26" r="16" fill="#17B471" />
      <path
        d="M108.287 22.2993C108.674 21.9053 109.307 21.8996 109.701 22.2866C110.064 22.6438 110.097 23.2107 109.796 23.6058L109.713 23.7007L102.838 30.7007C102.477 31.0691 101.901 31.0974 101.506 30.7857L101.412 30.7007L98.2866 27.5189C97.8996 27.1249 97.9053 26.4917 98.2993 26.1047C98.663 25.7475 99.2305 25.7249 99.62 26.0334L99.7134 26.1175L102.125 28.573L108.287 22.2993Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_21402_5842"
          x="0"
          y="0"
          width="140"
          height="140"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.305882 0 0 0 0 0.34902 0 0 0 0 0.470588 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21402_5842" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21402_5842" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

IconNewCodeSent.propTypes = simpleIconPropsTypes;
