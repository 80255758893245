export const INIT = 'init';
export const ERROR = 'Error';
export const CODE_MISMATCH_EXCEPTION = 'CodeMismatchException';
export const EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException';
export const EXPIRED_CODE_EXCEPTION_FORGET_PASSWORD = 'ExpiredCodeExceptionForgetPassword';
export const USER_ALREADY_CONFIRMED_CODE = 'UserAlreadyConfirmed';
export const EXPIRED_TOKEN_EXCEPTION_CODE = 'ExpiredTokenException';
export const USER_CONFIRMED_CODE = 'UserConfirmed';
export const TOO_MANY_FAILED_ATTEMPTS_EXCEPTION_CODE = 'TooManyFailedAttemptsException';
export const LIMITED_EXCEEDED_EXCEPTION_CODE = 'LimitExceededException';
export const VERIFICATION_CODE_RESENT_CODE = 'VerificationCodeResent';
