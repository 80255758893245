import { useEffect, useRef } from 'react';
import useLogout from './useLogout';

export default function useInactivityLogout(timeout = 5 * 60 * 1000) {
  const logout = useLogout(); // use your existing logout hook
  const timerIdRef = useRef(null);

  useEffect(() => {
    // Function to reset the existing timer
    const resetTimer = () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
      // Start a new timer for `timeout` milliseconds
      timerIdRef.current = setTimeout(() => {
        // Inactivity threshold reached -> logout the user
        logout(true);
      }, timeout);
    };

    // Start the timer initially
    resetTimer();

    // Listen for user activity events
    const events = ['mousemove', 'keydown', 'click', 'touchstart'];
    events.forEach((evt) => window.addEventListener(evt, resetTimer));

    // Cleanup when component unmounts or dependencies change
    return () => {
      events.forEach((evt) => window.removeEventListener(evt, resetTimer));
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, [logout, timeout]);

  return null; // No visual output, just the effect
}
