import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../../components/common/Typography';
import { ROUTE_SIGNIN } from '../../../../constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IconNewCodeSent } from '../../../../components/svg/Icons';

const NewCodeSent = (props) => {
  const { classes, trans, attemptsCount, email, resendVerification, pending } = props;
  const history = useHistory();

  /***
   *
   */
  const goToLogin = () => {
    history.push(ROUTE_SIGNIN.path);
  };

  return (
    <div className={classes.cardContainer}>
      <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center">
        <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
          <Grid item xs={12} className={classes.signUpForm}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.iconNewCodeSentContainer}>
                <IconNewCodeSent />
              </Grid>
              <Grid item xs={12} className={classes.newCodeSentContainer}>
                <Typography className={classes.newCodeSent} fontFamily="lexend" weight="500">
                  {trans('new-code-has-been-sent')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.newCodeSentSubTitleContainer}>
                <Typography className={classes.newCodeSentSubTitle} fontFamily="manrope" weight="400">
                  {`${trans('for-further-instructions-check-your-email')}:`}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.newCodeSentEmailContainer}>
                <Typography className={classes.newCodeSentEmail} fontFamily="manrope" weight="600">
                  {email}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.solutionBox}>
                <Typography variant="body2" fontFamily="lexend" weight="600">
                  {`${trans('cant-find-it')}?`}
                </Typography>
                <div className={classes.plr1rem}>
                  <Typography variant="body2" fontFamily="manrope" weight="400">
                    {`1. ${trans('check-your-spam-folder')}.`}
                  </Typography>
                </div>
                <div className={classes.plr1rem}>
                  <Typography variant="body2" fontFamily="manrope" weight="400">
                    {`2. ${trans('make-sure-email-sent-the-correct-address')}.`}
                  </Typography>
                </div>
              </Grid>
              {attemptsCount <= 1 ? (
                <Grid item xs={12} className={classes.notReceiveLetterContainer}>
                  <>
                    <span className={classes.notReceiveLetter}>{`${trans('did-not-receive-letter')}? `}</span>
                    <span
                      className={classes.notReceiveLetterResend}
                      onClick={pending ? null : resendVerification}
                    >{`${trans('re-send')}`}</span>
                  </>
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.notReceiveLetterContainer}>
                  <>
                    <div className={classes.weJustSend}>{`${trans('we-have-just-sent-email-once-more')}. `}</div>
                    <div className={classes.ifYouCantFind}>{`${trans(
                      'if-you-cant-find-please-check-possible-solutions-above'
                    )}.`}</div>
                  </>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewCodeSent;
