import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from '../../../components/layouts/AuthLayout';
import { ROUTE_SIGNUP } from '../../../constants';
import useVerification from './useVerification';
import Error from './components/Error';
import {
  CODE_MISMATCH_EXCEPTION,
  ERROR,
  INIT,
  LIMITED_EXCEEDED_EXCEPTION_CODE,
  USER_ALREADY_CONFIRMED_CODE,
  USER_CONFIRMED_CODE,
  VERIFICATION_CODE_RESENT_CODE,
} from '../../../utils/exceptions';
import VerifyEmail from './components/VerifyEmail';
import AlreadyVerified from './components/AlreadyVerified';
import Verified from './components/Verified';
import ReachedAttempts from './components/ReachedAttempts';
import NewCodeSent from './components/NewCodeSent';

const Verification = (props) => {
  const {
    classes,
    query,
    trans,
    state,
    pending,
    verifyAccount,
    resendVerification,
    validateCode,
    setCode,
    codeError,
    verificationResult,
    attemptsCount,
    email,
  } = useVerification();

  return !query.email ? (
    <Redirect to={ROUTE_SIGNUP.path} />
  ) : (
    <AuthLayout>
      {(verificationResult === INIT || verificationResult === CODE_MISMATCH_EXCEPTION) && (
        <VerifyEmail
          classes={classes}
          query={query}
          trans={trans}
          state={state}
          pending={pending}
          verifyAccount={verifyAccount}
          resendVerification={resendVerification}
          validateCode={validateCode}
          setCode={setCode}
          codeError={codeError}
          verificationResult={verificationResult}
        />
      )}
      {verificationResult === USER_CONFIRMED_CODE && <Verified classes={classes} trans={trans} />}
      {verificationResult === USER_ALREADY_CONFIRMED_CODE && <AlreadyVerified classes={classes} trans={trans} />}
      {verificationResult === VERIFICATION_CODE_RESENT_CODE && (
        <NewCodeSent
          classes={classes}
          trans={trans}
          attemptsCount={attemptsCount}
          email={email}
          resendVerification={resendVerification}
          pending={pending}
        />
      )}
      {verificationResult === LIMITED_EXCEEDED_EXCEPTION_CODE && <ReachedAttempts classes={classes} trans={trans} />}
      {verificationResult === ERROR && <Error classes={classes} trans={trans} />}
    </AuthLayout>
  );
};

export default Verification;
