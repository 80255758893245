import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import { Button } from '../../common/Buttons';
import { ModalDialog } from '../../common/Dialogs';
import { TextField } from '../../common/Inputs';
import { useForm } from '../../../hooks/useForm';
import { useTrans } from '../../../services/i18n';
import { useMediaQueryDown } from '../../../hooks/useMediaQuery';
import { TagsAutocomplete } from '../../autocompletes/TagsAutocomplete';
import { getInitialValues, useScheme } from './helpers';
import { useAlertContext } from '../../../contexts/AlertContext';

const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: true };

export default function SavePipelineForm({
  item,
  open,
  onClose,
  addPipeline,
  editPipeline,
  getTagsByUser,
  pipelineId,
}) {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const [isSend, setIsSend] = React.useState(false);
  const tags = item?.tags;
  const isSmallScreen = useMediaQueryDown('xs');

  const onSubmit = useCallback(
    (values) => {
      if (values.description === '') {
        values.description = ' ';
      }

      const editting = !!values.id;
      if (editting && typeof editPipeline !== 'function') return;
      if (!editting && typeof addPipeline !== 'function') return;

      setIsSend(true);

      Promise.resolve(editting ? editPipeline(values) : addPipeline(values))
        .then(() => {
          successAlert(editting ? 'Pipeline saved!' : 'Pipeline added!');
          onClose();
          resetForm();
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose, addPipeline, infoAlert, errorAlert, successAlert, editPipeline]
  );

  const { getFieldProps, handleSubmit, values, isValid, resetForm } = useForm(
    schema,
    getInitialValues(item),
    onSubmit,
    formOptions
  );

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans(values?.id ? 'EditPipeline' : 'AddPipeline')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" disabled={isSend} onClick={onClose}>
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={!isValid} pending={isSend} onClick={handleSubmit}>
              {trans(values?.id ? 'save' : 'create')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item sm={8} xs={12}>
          <TextField
            label={trans('name')}
            placeholder={trans('required')}
            required
            maxLength={80}
            {...getFieldProps('title')}
          />
        </Grid>
        {values?.id ? undefined : (
          <Grid item sm={4} xs={12}>
            <TextField
              label={trans('NumberOfStages')}
              placeholder={trans('required')}
              required
              maxLength={2}
              type="number"
              align={isSmallScreen ? 'left' : 'center'}
              {...getFieldProps('stages')}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            label={trans('description')}
            // placeholder={trans('required')}
            // required
            multiline
            rows={4}
            rowsMax={10}
            maxLength={255}
            {...getFieldProps('description')}
          />
        </Grid>

        <Grid item xs={12}>
          <TagsAutocomplete
            label={trans('Tags')}
            value={tags?.create}
            placeholder={trans('TypeToSearchOrCreateTags')}
            required
            multi
            {...getFieldProps('tags')}
            getTags={getTagsByUser}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
}
